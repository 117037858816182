import { Component, OnInit, ViewChild, Input, Inject, LOCALE_ID }           from '@angular/core';
import { CalendarComponent }                                                from 'ionic2-calendar';

@Component({
  selector: 'app-cabecera-fecha',
  templateUrl: './cabecera-fecha.component.html',
  styleUrls: ['./cabecera-fecha.component.scss'],
})
export class CabeceraFechaComponent implements OnInit {


    //para poder llamar a elementos de la pagina que contiene este componente siguo el tutorial...
    //...https://fireship.io/lessons/sharing-data-between-angular-components-four-methods/
    @Input() input_obj_calendario: CalendarComponent; 
    @Input() input_conf_calendario: any; 
    @Input() input_str_Anio: string; 
    @Input() input_str_Mes: string; 
    @Input() input_int_Semana_del_mes: number; 
    @Input() input_str_Dia: string; 
    @Input() input_month_seleccionable: string; //creia que sería boolean pero llega como "string", asi que lo declaro asi.
    @Input() input_week_seleccionable: string; //creia que sería boolean pero llega como "string", asi que lo declaro asi.
    @Input() input_day_seleccionable: string; //creia que sería boolean pero llega como "string", asi que lo declaro asi.

    constructor(@Inject(LOCALE_ID) private locale: string) {

        
        }

    ngOnInit() {
        //this.Cambiar_CalendarMode (this.input_conf_calendario.mode); //para que aparezca seleccionada la opción correcta en la cabecera
        //console.log ("this.input_month_seleccionable", this.input_month_seleccionable)
        //console.log ("this.input_day_seleccionable", this.input_day_seleccionable)
        //"input_week_seleccionable", "input_day_seleccionable"")
        }


    next() {
        this.input_obj_calendario.slideNext();
        }

    back() {
        this.input_obj_calendario.slidePrev();
        }

    Cambiar_CalendarMode_OBSOLETA (prmtrCalendarMode) {
        console.log ("dentro de Cambiar_CalendarMode", prmtrCalendarMode)
        var bln_cambiar_tipo_de_vista = true;
        if (prmtrCalendarMode == "month" )  {
            if (this.input_month_seleccionable == 'false') {bln_cambiar_tipo_de_vista = false}
            }
        if (prmtrCalendarMode == "week" )  {
            if (this.input_week_seleccionable == 'false') {bln_cambiar_tipo_de_vista = false}
            }
        if (prmtrCalendarMode == "day" )  {
            if (this.input_day_seleccionable == 'false') {bln_cambiar_tipo_de_vista = false}
            }

        //
        if (bln_cambiar_tipo_de_vista) {
            this.input_conf_calendario.mode = prmtrCalendarMode;
            }

        console.log ("this.input_conf_calendario.mode", this.input_conf_calendario.mode)

        }

}
